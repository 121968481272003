.card-header{
    font-size: 1.6em;
    text-align: center;
      z-index:11;
}

.city-label{
  font-size: 1.2em;
  text-align: center;
  z-index:10;
  position: relative;
}

.card-flag{
  font-size: 3em;
  z-index:10;
}

.card-body{
  position: relative;
  background: linear-gradient(to bottom, #90dffe 0%,#38a3d1 100%);
}

.clock{
    font-size: 1.9em;
    text-align: center;
    z-index:8;
    position: relative;
}
.time{
    font-size: 1.9em;
    text-align: center;
    z-index:8;
    position: relative;
   font-family: 'Segment7Standard';
   font-weight: normal;
   font-style: italic;
}
.colon{
      font-size: 1.6em;
   font-family: 'Arial';
   vertical-align: top;
}

.container{
  margin-top: 20px;
}


.flag-icon{
  margin-right: 10px;
}



.hrow {
font-weight:bold;
color: black;
font-size: 2em
}
.hrow td{
padding-top: 20px;
width: 140px;
font-size: 1.3em;
}


.night{
  color:white;
}
